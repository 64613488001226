// This file was automatically generated by _codebuilder.py.
// これは _codebuilder.py で自動的に生成されたファイルです。
// 这个文件是由 _codebuilder.py 自动生成的。

import project_icon_elden from '../resource/project_icon/elden.png';
import project_icon_kikaku from '../resource/project_icon/kikaku.png';
import project_icon_maiweb from '../resource/project_icon/maiweb.png';
import project_icon_wotagei from '../resource/project_icon/wotagei.png';

export const ProjectIcons = {
  i_elden: project_icon_elden,
  i_kikaku: project_icon_kikaku,
  i_maiweb: project_icon_maiweb,
  i_wotagei: project_icon_wotagei,
};

