import Illust, { IllustType } from '../utils/illust';
import { IllustImages, IllustImageThumbs } from '../resourcesReader/illustReader';
export const illustDesc: Illust[] = [
  {
    url: IllustImages[0],
    thumburl: IllustImageThumbs[0],
    title: '絢爛たる沼津市夜景色',
    date: '2024-10-20',
    description: '静岡県沼津市 香貫山。彗星撮りに行ったらこんな絶景にあえるとは思わなかったわー',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[1],
    thumburl: IllustImageThumbs[1],
    title: '黒部湖と雪かぶる赤牛岳',
    date: '2024-5-4',
    description: '富山県立山町。実は某少女バンドアニメの聖地でもある',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[2],
    thumburl: IllustImageThumbs[2],
    title: '淡島へヨーソロー＾',
    date: '2024-9-10',
    description: '静岡県沼津市',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[3],
    thumburl: IllustImageThumbs[3],
    title: '上高地',
    date: '2023-9-11',
    description: '長野県松本市 上高地',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[4],
    thumburl: IllustImageThumbs[4],
    title: '上高地',
    date: '2023-9-11',
    description: '長野県松本市 上高地',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[5],
    thumburl: IllustImageThumbs[5],
    title: '積雲',
    date: '2023-9-30',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[6],
    thumburl: IllustImageThumbs[6],
    title: '日の出待ち',
    date: '2023-10-22',
    description: '千葉県南房総市。サンキューちばパス、いいよね～',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[7],
    thumburl: IllustImageThumbs[7],
    title: ')－(',
    date: '2024-2-17',
    description: '山梨県身延町下部。)－(　)－(　)－(　)－(',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[8],
    thumburl: IllustImageThumbs[8],
    title: '本栖湖の朝',
    date: '2024-2-18',
    description: '山梨県身延町',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[9],
    thumburl: IllustImageThumbs[9],
    title: '天童アリスちゃん、天童駅だよ',
    date: '2024-3-17',
    description: '山形県天童市。将棋の駒の名産地',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[10],
    thumburl: IllustImageThumbs[10],
    title: '沼津から身延線経由で帰京途中',
    date: '2024-7-29',
    description: '山梨県南部町',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[11],
    thumburl: IllustImageThumbs[11],
    title: '本栖湖で志摩リンと朝珈琲',
    date: '2024-8-5',
    description: '山梨県身延町',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[12],
    thumburl: IllustImageThumbs[12],
    title: '八丈富士ふれあい牧場',
    date: '2024-9-8',
    description: '東京都八丈町。牧場のオーナーさんと八丈方言の分布についていっぱい話してた',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[13],
    thumburl: IllustImageThumbs[13],
    title: '遠洋船上',
    date: '2024-9-8',
    description: '御蔵島付近',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[14],
    thumburl: IllustImageThumbs[14],
    title: '自分用Vtuber設定',
    date: '2022-8-18',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[15],
    thumburl: IllustImageThumbs[15],
    title: '高尾山から見た4都県',
    date: '2023-10-12',
    description: '富士山見えなくていかに遺憾なり',
    type: [IllustType.Photo, IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[16],
    thumburl: IllustImageThumbs[16],
    title: '高尾山から見た4都県-色付け',
    date: '2023-10-12',
    description: '',
    type: [IllustType.Photo, IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[17],
    thumburl: IllustImageThumbs[17],
    title: '首都圏鉄道路線略図',
    date: '2023-9-12',
    description: '適当に塗ったやつ',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[18],
    thumburl: IllustImageThumbs[18],
    title: '中国省份拓扑图',
    date: '2022-10-17',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[19],
    thumburl: IllustImageThumbs[19],
    title: 'Suviet Union',
    date: '2020-03-25',
    description: '这就是苏联.jpg',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[20],
    thumburl: IllustImageThumbs[20],
    title: '四国じゃ',
    date: '2021-08-12',
    description: '四国です。',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[21],
    thumburl: IllustImageThumbs[21],
    title: '秋のるしあ',
    date: '2019-12-18',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[22],
    thumburl: IllustImageThumbs[22],
    title: '架空設定 Chobarian',
    date: '2020-01-19',
    description: '某架空世界的民族介绍',
    type: [IllustType.Illust, IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[23],
    thumburl: IllustImageThumbs[23],
    title: '渡島半島に抱える函館市',
    date: '2024-3-27',
    description: 'GK801 東京(成田)→旭川',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[24],
    thumburl: IllustImageThumbs[24],
    title: 'フクキタル',
    date: '2021-04-16',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[25],
    thumburl: IllustImageThumbs[25],
    title: 'はあと',
    date: '2019-11-08',
    description: '',
    type: [IllustType.Illust],
    nsfw: true,
  },
  {
    url: IllustImages[26],
    thumburl: IllustImageThumbs[26],
    title: 'ネイチャ',
    date: '2022-04-17',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[27],
    thumburl: IllustImageThumbs[27],
    title: '寧々',
    date: '2021-10-03',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[28],
    thumburl: IllustImageThumbs[28],
    title: 'にこ',
    date: '2019-11-25',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[29],
    thumburl: IllustImageThumbs[29],
    title: 'にこ',
    date: '',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[30],
    thumburl: IllustImageThumbs[30],
    title: 'バレンタイン',
    date: '2022-04-01',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[31],
    thumburl: IllustImageThumbs[31],
    title: 'コハル',
    date: '2022-07-28',
    description: '',
    type: [IllustType.Illust],
    nsfw: true,
  },
  {
    url: IllustImages[32],
    thumburl: IllustImageThumbs[32],
    title: 'ターボ',
    date: '2021-07-16',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[33],
    thumburl: IllustImageThumbs[33],
    title: 'みく',
    date: '2022-03-23',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[34],
    thumburl: IllustImageThumbs[34],
    title: '東根室駅',
    date: '2024-3-30',
    description: '北海道根室市。日本最東端の駅。もうすぐ廃駅になるのなあ...',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[35],
    thumburl: IllustImageThumbs[35],
    title: 'ヨハネ',
    date: '2018-01-22',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[36],
    thumburl: IllustImageThumbs[36],
    title: '自分用アイコン',
    date: '2020-11-07',
    description: 'いろんなところで使ってるって感じ',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[37],
    thumburl: IllustImageThumbs[37],
    title: '？',
    date: '2020-01-12',
    description: '',
    type: [IllustType.Illust],
    nsfw: true,
  },
  {
    url: IllustImages[38],
    thumburl: IllustImageThumbs[38],
    title: 'おっぱい勝負-線画',
    date: '2019-12-17',
    description: '',
    type: [IllustType.Illust],
    nsfw: true,
  },
  {
    url: IllustImages[39],
    thumburl: IllustImageThumbs[39],
    title: 'おっぱい勝負',
    date: '2019-12-17',
    description: '',
    type: [IllustType.Illust],
    nsfw: true,
  },
  {
    url: IllustImages[40],
    thumburl: IllustImageThumbs[40],
    title: 'ホロライブCN鯖鉄道路線図',
    date: '2020-5-15',
    description: '前ホロライブ公式中国語字幕班が運営してたMC鯖の鉄道地図。時々vtberたちも遊んでくる',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[41],
    thumburl: IllustImageThumbs[41],
    title: '架空',
    date: '2020-10-24',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[42],
    thumburl: IllustImageThumbs[42],
    title: '甘県市町村一覧',
    date: '2023-05-17',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[43],
    thumburl: IllustImageThumbs[43],
    title: '両州地方の県と令制国',
    date: '2023-05-17',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[44],
    thumburl: IllustImageThumbs[44],
    title: '沼津鉄道構想',
    date: '2024-09-29',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[45],
    thumburl: IllustImageThumbs[45],
    title: '紺野純子と唐津城',
    date: '2023-10-2',
    description: '佐賀県唐津市',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[46],
    thumburl: IllustImageThumbs[46],
    title: '青島市を日本風行政区画にしたら',
    date: '2024-05-11',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[47],
    thumburl: IllustImageThumbs[47],
    title: '沼津鉄道　内浦駅',
    date: '2024-09-29',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[48],
    thumburl: IllustImageThumbs[48],
    title: 'ホロCN鯖粽町観光案内図',
    date: '',
    description: '',
    type: [IllustType.Map],
    nsfw: false,
  },
  {
    url: IllustImages[49],
    thumburl: IllustImageThumbs[49],
    title: 'ことり',
    date: '',
    description: '',
    type: [IllustType.Illust],
    nsfw: false,
  },
  {
    url: IllustImages[50],
    thumburl: IllustImageThumbs[50],
    title: '天の川 in 八丈富士',
    date: '2024-9-10',
    description: '東京都八丈町',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[51],
    thumburl: IllustImageThumbs[51],
    title: '青島市スカイライン',
    date: '2024-9-10',
    description: '山東省青島市市南区',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[52],
    thumburl: IllustImageThumbs[52],
    title: '太白山登山道から仙台市を眺望',
    date: '2024-3-18',
    description: '宮城県仙台市太白区',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[53],
    thumburl: IllustImageThumbs[53],
    title: '「色づく世界の明日から」',
    date: '2023-10-3',
    description: '長崎県長崎市',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[54],
    thumburl: IllustImageThumbs[54],
    title: '「中国人客请不要把光棒放进酒杯里」',
    date: '',
    description: '',
    type: [IllustType.Photo, IllustType.Meal],
    nsfw: false,
  },
  {
    url: IllustImages[55],
    thumburl: IllustImageThumbs[55],
    title: '准备回家前',
    date: '2022-11-05',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[56],
    thumburl: IllustImageThumbs[56],
    title: '生日礼物',
    date: '2022-11-29',
    description: '收到的生日礼物',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[57],
    thumburl: IllustImageThumbs[57],
    title: '宮ケ瀬湖ダム放水',
    date: '2023-04-29',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[58],
    thumburl: IllustImageThumbs[58],
    title: '淡島から内浦湾展望',
    date: '2023-05-02',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[59],
    thumburl: IllustImageThumbs[59],
    title: '沼津港名物 天丼',
    date: '2023-09-02',
    description: '結局食べきれなく持ち帰って3食分でやっと食べ終わった',
    type: [IllustType.Photo, IllustType.Meal],
    nsfw: false,
  },
  {
    url: IllustImages[60],
    thumburl: IllustImageThumbs[60],
    title: '大瀬崎までサイクリング',
    date: '2023-09-03',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[61],
    thumburl: IllustImageThumbs[61],
    title: '松本城',
    date: '2023-09-09',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[62],
    thumburl: IllustImageThumbs[62],
    title: '小倉城',
    date: '2023-10-01',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[63],
    thumburl: IllustImageThumbs[63],
    title: '門司港',
    date: '2023-10-01',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[64],
    thumburl: IllustImageThumbs[64],
    title: '唐津駅にて',
    date: '2023-10-02',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[65],
    thumburl: IllustImageThumbs[65],
    title: 'ゾンビランド・サガのあれ',
    date: '2023-10-02',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[66],
    thumburl: IllustImageThumbs[66],
    title: '熊本城',
    date: '2023-10-05',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[67],
    thumburl: IllustImageThumbs[67],
    title: '阿蘇山火口',
    date: '2023-10-07',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[68],
    thumburl: IllustImageThumbs[68],
    title: '久留里線上総亀山駅',
    date: '2023-10-21',
    description: '廃線前記念',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[69],
    thumburl: IllustImageThumbs[69],
    title: '淡島フェリーと富士山',
    date: '2023-11-23',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[70],
    thumburl: IllustImageThumbs[70],
    title: '十国峠',
    date: '2023-11-24',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[71],
    thumburl: IllustImageThumbs[71],
    title: '東海道新幹線にて',
    date: '2023-12-07',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[72],
    thumburl: IllustImageThumbs[72],
    title: '三津浜でヲタ芸',
    date: '2023-12-08',
    description: 'Aqours 1st singleでヲタ芸盛り上がった',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[73],
    thumburl: IllustImageThumbs[73],
    title: 'はじめてのヲタ芸聖地上野公園',
    date: '2023-12-10',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[74],
    thumburl: IllustImageThumbs[74],
    title: 'ラブライブカフェ',
    date: '2023-12-10',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei, IllustType.Meal],
    nsfw: false,
  },
  {
    url: IllustImages[75],
    thumburl: IllustImageThumbs[75],
    title: '彦根城',
    date: '2023-12-23',
    description: '現存１２天守その１',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[76],
    thumburl: IllustImageThumbs[76],
    title: '雑旅',
    date: '2023-12-25',
    description: '雑旅や、ああ雑旅や、雑旅や',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[77],
    thumburl: IllustImageThumbs[77],
    title: '福島駅にて',
    date: '2024-03-17',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[78],
    thumburl: IllustImageThumbs[78],
    title: '根室港',
    date: '2024-03-30',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[79],
    thumburl: IllustImageThumbs[79],
    title: '本栖湖',
    date: '2024-04-20',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[80],
    thumburl: IllustImageThumbs[80],
    title: 'Japan Scenery Stereotype',
    date: '2024-04-20',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[81],
    thumburl: IllustImageThumbs[81],
    title: '湯沢',
    date: '2024-05-01',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[82],
    thumburl: IllustImageThumbs[82],
    title: '金沢駅の蓮の空店舗',
    date: '2024-05-01',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[83],
    thumburl: IllustImageThumbs[83],
    title: '雨晴海岸にて',
    date: '2024-05-02',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[84],
    thumburl: IllustImageThumbs[84],
    title: '黒部峡谷トロッコ列車',
    date: '2024-05-03',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[85],
    thumburl: IllustImageThumbs[85],
    title: '岩峅寺',
    date: '2024-05-03',
    description: '富山特有の方言字「峅」',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[86],
    thumburl: IllustImageThumbs[86],
    title: '雪の大谷',
    date: '2024-05-04',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[87],
    thumburl: IllustImageThumbs[87],
    title: '立山',
    date: '2024-05-04',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[88],
    thumburl: IllustImageThumbs[88],
    title: 'つくば　雨',
    date: '2024-05-28',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[89],
    thumburl: IllustImageThumbs[89],
    title: '「結束バンド」称号達成ーー',
    date: '2024-06-14',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[90],
    thumburl: IllustImageThumbs[90],
    title: '例の吉野家',
    date: '2024-06-16',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[91],
    thumburl: IllustImageThumbs[91],
    title: '金沢到着',
    date: '2024-06-27',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[92],
    thumburl: IllustImageThumbs[92],
    title: '流山鉄道流山駅',
    date: '2024-06-29',
    description: '昭和風感じる',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[93],
    thumburl: IllustImageThumbs[93],
    title: '曜ちゃんと曜ちゃんっちで',
    date: '2024-07-07',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei, IllustType.Meal],
    nsfw: false,
  },
  {
    url: IllustImages[94],
    thumburl: IllustImageThumbs[94],
    title: '「恋になりたいアクアリウム」',
    date: '2024-07-08',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[95],
    thumburl: IllustImageThumbs[95],
    title: '韮山反射炉　スタンプラリー',
    date: '2024-07-09',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[96],
    thumburl: IllustImageThumbs[96],
    title: '日坂町',
    date: '2024-07-09',
    description: 'ぽんこつポン子聖地巡礼',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[97],
    thumburl: IllustImageThumbs[97],
    title: '戸田港',
    date: '2024-07-09',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[98],
    thumburl: IllustImageThumbs[98],
    title: '沼津夏祭り',
    date: '2024-07-27',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[99],
    thumburl: IllustImageThumbs[99],
    title: 'ワイ',
    date: '2024-08-10',
    description: '',
    type: [IllustType.Photo],
    nsfw: true,
  },
  {
    url: IllustImages[100],
    thumburl: IllustImageThumbs[100],
    title: '八丈町　足湯きらめき',
    date: '2024-09-08',
    description: '♪あつすぎの歌♪',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[101],
    thumburl: IllustImageThumbs[101],
    title: '八丈料理',
    date: '2024-09-08',
    description: '',
    type: [IllustType.Photo, IllustType.Meal],
    nsfw: false,
  },
  {
    url: IllustImages[102],
    thumburl: IllustImageThumbs[102],
    title: '伊豆諸島の夜光キノコ',
    date: '2024-09-09',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[103],
    thumburl: IllustImageThumbs[103],
    title: '御蔵島',
    date: '2024-09-10',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[104],
    thumburl: IllustImageThumbs[104],
    title: '箱根',
    date: '2024-09-11',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[105],
    thumburl: IllustImageThumbs[105],
    title: 'さっき○○してた時見てただろう？',
    date: '2024-09-21',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[106],
    thumburl: IllustImageThumbs[106],
    title: '草',
    date: '2024-10-25',
    description: '',
    type: [IllustType.Photo, IllustType.Meal],
    nsfw: false,
  },
  {
    url: IllustImages[107],
    thumburl: IllustImageThumbs[107],
    title: '荒井まやかちゃん、荒井駅だよ',
    date: '2024-10-26',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[108],
    thumburl: IllustImageThumbs[108],
    title: '次の場所へ旅立つのだ',
    date: '2024-10-26',
    description: '',
    type: [IllustType.Photo, IllustType.Junrei],
    nsfw: false,
  },
  {
    url: IllustImages[109],
    thumburl: IllustImageThumbs[109],
    title: '猫島',
    date: '2024-10-27',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[110],
    thumburl: IllustImageThumbs[110],
    title: '馬の背',
    date: '2024-10-29',
    description: '松島や',
    type: [IllustType.Photo],
    nsfw: false,
  },
  {
    url: IllustImages[111],
    thumburl: IllustImageThumbs[111],
    title: '进化为星星批',
    date: '2024-11-09',
    description: '',
    type: [IllustType.Photo],
    nsfw: false,
  },
];
