// This file was automatically generated by _codebuilder.py.
// これは _codebuilder.py で自動的に生成されたファイルです。
// 这个文件是由 _codebuilder.py 自动生成的。

export const BackgroundImages = [
  'https://www.elpwc.com/imagebank/homepagebgi/0.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/1.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/2.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/3.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/4.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/5.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/6.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/7.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/8.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/9.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/10.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/11.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/12.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/13.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/14.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/15.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/16.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/17.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/18.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/19.jpg',
  'https://www.elpwc.com/imagebank/homepagebgi/20.jpg',
];
