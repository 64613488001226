import { ProjectIcons } from '../resourcesReader/projectIconReader';
import { ProjectImg } from '../resourcesReader/projectImgReader';
import { DevState } from '../utils/project';
import { StaticProject } from '../utils/staticProject';

export const static_projects_zh_cn: StaticProject[] = [
  {
    name: 'WOTAGEIPEDIA - ヲタ芸百科',
    url: 'http://wotagei.elpwc.com',
    github: 'https://github.com/elpwc/wotageipedia',
    status: DevState.Developing,
    desc: '目的是创造一个将各个视频网站上零散分布的技连视频中的技系统地登记、归类在一起，能方便又快捷地按照 「技名・系・动作(?)・本家・...」 等各种信息查技的网站。解决wota艺圈内查技难，ori技容易埋没在视频站，界隈难以联络到的问题',
    image: './images/wotageipedia.png',
    imageWidth: '40%',
    tags: 'React, Python, TypeScript, AntDesign',
    icon: ProjectIcons.i_wotagei,
  },
  {
    name: 'wniko homepage',
    url: '',
    github: 'https://github.com/elpwc/wniko-homepage',
    status: DevState.Developing,
    desc: '真正的支持留言，博客，项目展示等等功能的个人主页',
    image: '',
    tags: 'React, Nest.js, TypeORM, TypeScript, AntDesign',
  },
  {
    name: 'XP系统测试器',
    url: 'http://www.elpwc.com/test',
    github: 'https://github.com/elpwc/seiheki-test',
    status: DevState.Developing,
    desc: '一个提供XP系统维护功能的在线工具(?)',
    image: './images/xp.png',
    imageWidth: '8%',
    tags: 'Svelte, TypeScript, PHP',
  },
  {
    name: '吃掉小涩图',
    url: 'http://www.elpwc.com/eatsetu',
    github: 'https://github.com/elpwc/EatSetu',
    status: DevState.Done,
    desc: '跟风作()',
    image: '',
    tags: 'PHP, JS',
  },
  {
    name: '⊌PC Leaver .web - 批量出校请假服务',
    url: 'http://www.elpwc.com/upcleaver/',
    github: 'https://github.com/elpwc/UPC-Leaver.web',
    status: DevState.MaintainingStopped,
    desc: '',
    image: '',
    tags: 'PHP, JS, BootStrap',
  },
  {
    name: 'ANIME-SEIRI.web',
    url: 'http://www.elpwc.com/animeseiri',
    github: 'https://github.com/elpwc/ANIME-SEIRI.web',
    status: DevState.Deprecated,
    desc: '',
    image: '',
    tags: 'PHP, SASS, BootStrap',
  },
  {
    name: 'City Counter Game',
    url: '',
    github: 'https://github.com/elpwc/CityCounterGame',
    status: DevState.Planning,
    desc: '猜城市名的web游戏',
    image: '',
  },
  {
    name: '웹페이지 漢字 콘버터 Chrome ext',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '将韩文页面内汉字词全部转换为汉字的chrome插件',
    image: '',
  },
  {
    name: '地名词源收录站',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '',
    image: '',
  },
  {
    name: 'CJKVR对照图在线排版',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '',
    image: '',
  },
  {
    name: '汉文训读在线排版',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '',
    image: '',
  },
];

export const static_projects_ja: StaticProject[] = [
  {
    name: 'OnlineEldenRingMap - 老头环地图',
    url: 'http://www.elpwc.com/eldenringmap',
    github: 'https://github.com/elpwc/EldenRingOnlineMap',
    status: DevState.Done,
    desc: 'ゲームElden Scrollのプレイヤ編集型オンラインマップ',
    image: '',
    imageWidth: '',
    tags: 'Svelte, TypeScript, PHP, Leaflet',
    icon: ProjectIcons.i_elden,
  },
  {
    name: 'WOTAGEIPEDIA - ヲタ芸百科',
    url: 'http://www.elpwc.com/wotageipedia',
    github: 'https://github.com/elpwc/wotageipedia',
    status: DevState.Developing,
    desc: (
      <>
        ヲタ芸wiki project <br /> 開発中
      </>
    ),
    image: ProjectImg.i_wotageipedia,
    imageWidth: '40%',
    tags: 'React, Python, TypeScript, AntDesign',
    icon: ProjectIcons.i_wotagei,
  },
  {
    name: 'Kikaku',
    url: 'https://www.elpwc.com/kikaku',
    github: 'https://github.com/elpwc/kikaku',
    status: DevState.Done,
    desc: 'スケジュール管理（Keikakuなのでは？）',
    image: '',
    tags: 'React, Nest.js, TypeORM, TypeScript',
  },
  {
    name: 'maiweb',
    url: 'https://www.elpwc.com/dev/maiweb/',
    github: 'https://github.com/elpwc/maiweb',
    status: DevState.Developing,
    desc: '某音ゲーの譜面閲覧サイト',
    image: '',
    imageWidth: '',
    tags: 'React, TypeScript, Canvas',
  },
  {
    name: 'react-maisim',
    url: '',
    github: '',
    status: DevState.Done,
    desc: '某音ゲーをウェブ化させたReact Component',
    image: '',
    imageWidth: '',
    tags: 'React, TypeScript, Canvas',
  },
  {
    name: 'My行脚記録',
    url: '',
    github: 'https://github.com/elpwc/myAngyaRecord',
    status: DevState.Developing,
    desc: '前々から作りたかった市町村区行脚記録サイト',
    image: '',
    tags: 'leaflet',
  },
  {
    name: 'Handwriting',
    url: '',
    github: '',
    status: DevState.Done,
    desc: '文章を手書き風に変換するデスクトップアプリ',
    image: ProjectImg.i_handwriting,
    imageWidth: '40%',
    tags: 'Desktop, C#',
  },
  {
    name: 'XP系统测试器',
    url: 'http://www.elpwc.com/test',
    github: 'https://github.com/elpwc/seiheki-test',
    status: DevState.Developing,
    desc: '一个提供XP系统维护功能的在线工具(?)',
    image: ProjectImg.i_xp,
    imageWidth: '8%',
    tags: 'Svelte, TypeScript, PHP',
  },
  {
    name: '吃掉小涩图',
    url: 'http://www.elpwc.com/eatsetu',
    github: 'https://github.com/elpwc/EatSetu',
    status: DevState.Done,
    desc: '跟风作()',
    image: '',
    tags: 'PHP, JS',
  },
  {
    name: 'go?moku!',
    url: 'https://www.elpwc.com/reactgomoku/',
    github: '',
    status: DevState.Done,
    desc: '多人数プレイ可の五目並べ',
    image: '',
  },
  {
    name: 'Windows 10 WEB',
    url: 'https://www.elpwc.com/winsysweb/win10/',
    github: 'https://github.com/elpwc/windows-10-web',
    status: DevState.Developing,
    desc: 'ウェブ版windows10 desktop',
    image: '',
  },
  {
    name: '⊌PC Leaver .web - 批量出校请假服务',
    url: 'http://www.elpwc.com/upcleaver/',
    github: 'https://github.com/elpwc/UPC-Leaver.web',
    status: DevState.MaintainingStopped,
    desc: '某大学の一括休暇申請ウェブサービス',
    image: '',
    tags: 'PHP, JS, BootStrap',
  },
  {
    name: 'ANIME-SEIRI.web',
    url: 'http://www.elpwc.com/animeseiri',
    github: 'https://github.com/elpwc/ANIME-SEIRI.web',
    status: DevState.Deprecated,
    desc: '',
    image: '',
    tags: 'PHP, SASS, BootStrap',
  },
  {
    name: 'City Counter Game',
    url: '',
    github: 'https://github.com/elpwc/CityCounterGame',
    status: DevState.Planning,
    desc: '地名を当てるような地理系ゲーム',
    image: '',
  },
  {
    name: '웹페이지 漢字 콘버터 Chrome ext',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: 'A browser extension used for converting Sino-Korean words to Hanja in Korean webpages',
    image: '',
  },
  {
    name: '地名由来収録サイト',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '',
    image: '',
  },
  {
    name: 'CJKVR語彙対照図展示サイト',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '',
    image: '',
  },
  {
    name: '漢文訓読オンライン組版',
    url: '',
    github: '',
    status: DevState.Planning,
    desc: '',
    image: '',
  },
];
