// This file was automatically generated by _codebuilder.py.
// これは _codebuilder.py で自動的に生成されたファイルです。
// 这个文件是由 _codebuilder.py 自动生成的。

import project_img_handwriting from '../resource/project_img/handwriting.png';
import project_img_wotageipedia from '../resource/project_img/wotageipedia.png';
import project_img_xp from '../resource/project_img/xp.png';

export const ProjectImg = {
  i_handwriting: project_img_handwriting,
  i_wotageipedia: project_img_wotageipedia,
  i_xp: project_img_xp,
};

